/* Firefox */

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
  width: 10px;
  /* vertical scrollbar */
  height: 10px;
  /* horizontal scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
  ;
  border-radius: 14px;
  border: 1px solid #ccc;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}

/* Chrome, Edge, Safari */

* {
  scrollbar-width: thick;
  /* none | auto  */
  scrollbar-color: var(--ion-color-primary);
}

.marker-column {
  display: flex;
  align-items: center;

  ion-button {
    height: auto !important;
  }

  ion-icon {
    font-size: 28px;
    cursor: pointer;
    display: table-cell;
  }
}

.empty-data {
  margin: 5vh 0 !important;
  text-align: center;
  display: block;
}

ion-header {
  &:after {
    content: none !important;
  }
}

ion-toolbar {
  ion-icon {
    font-size: 20px;
    margin-right: 10px;
  }
}

* {

  p {
    margin-top: 0;
    font-size: 15px;
  }
}

ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

h1,
h2,
h3,
h4,
h5,
ion-button {
  font-family: 'Rajdhani';
  text-transform: initial;
  letter-spacing: 0;
  --box-shadow: none !important;
}

ion-button {
  font-size: 17px !important;
  font-weight: 600 !important;
  height: 44px !important;

  ion-icon {
    margin-right: 5px;
  }
}

ion-select,
ion-select-popover {
  ion-label {
    font-size: 14px !important;
  }
}

.sc-ion-alert-md-h {
  --min-width: 300px;
}

.alert-radio-label.sc-ion-alert-md {
  font-size: 14px !important;
  padding-inline-start: 35px !important;
}

h1 {
  font-size: 25px;
  font-weight: 700 !important;
  margin: 0;
  color: var(--ion-color-dark);
}

h2 {
  font-size: 22px;
  font-weight: 700 !important;
}

h3,
ion-label {
  font-size: 17px;
  font-weight: 700 !important;
  margin: 10px 0;
}

h4 {
  font-size: 15px;
  font-weight: 700 !important;
  line-height: 1;
  margin: 10px 0;
  white-space: initial;
}

h5 {
  font-size: 14px;
  font-weight: 600 !important;
  margin-top: 15px;
  margin-bottom: 0;
  color: var(--ion-color-border);
}

hr.divisor {
  border-top: 3px solid var(--ion-color-light);
  margin: 13px 0;
}

.markers-popover {
  --width: 250px;
  font-size: 14px !important;

  form {
    padding: 10px;

    ion-item {
      .input-default {
        font-size: 14px !important;
        width: 100% !important;
        margin: 0;
      }
    }

    ion-button {
      font-size: 16px !important;
    }
  }
}

ion-card {
  border-radius: 10px;
}

ion-chip {
  ion-label {
    font-size: 14px;
    font-weight: 600 !important;
  }

  ion-label,
  ion-icon {
    color: var(--ion-color-light);
  }
}

// esconder setas pra cima e pra baixo do input number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.custom-chip {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 11px;
  border-radius: 15px;
  --background: var(--ion-color-medium);
  color: var(--ion-color-border);
  margin-left: 0;
  height: auto;
}

.filter-chips {
  margin-top: 15px;

  .principal-filter-chip {
    --background: var(--ion-color-primary);
  }

  .default-filter-chip {
    --background: var(--ion-color-secondary);
  }
}

.add-new-filter-button,
.add-subfolder-button {
  --background: transparent;
  cursor: pointer;
  margin: 10px;

  ion-label {
    display: inherit !important;
  }

  h4 {
    font-size: 15px;
    margin-right: 5px;
  }

  ion-icon {
    font-size: 25px;
  }
}

.add-new-button {
  margin: 20px 10px;
}

.custom-searchbar {
  padding-top: 4px;
  padding-left: 0;
  padding-right: 0;
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
  font-size: 16px;
  font-family: 'Rajdhani';
  font-weight: 600;
}

.colored-data .native-textarea.sc-ion-textarea-md,
.colored-data,
.left-colored-data .native-textarea.sc-ion-textarea-md,
.left-colored-data {
  color: var(--ion-color-secondary);
  font-weight: 700;
  font-size: 16px;
  font-family: 'Rajdhani' !important;
}

.colored-data .native-textarea.sc-ion-textarea-md,
.colored-data {
  text-align: right;
}

.footer-buttons {
  margin-top: 30px;

  >ion-button {
    // height: 40px;
  }

  .block-button {
    font-weight: 800;
    margin-bottom: 15px;
  }

  .block-button.desktop {
    max-width: 320px;
  }
}

input.native-input.sc-ion-input-md {
  --padding-top: 10px !important;
  --padding-bottom: 10px !important;
}

.menu-modal {
  border-radius: 0 30px 0 0;
}

// customização de modal na versão PWA
@media only screen and (min-width: 768px) {

  .search-modal,
  .profile-modal,
  .notifications-modal,
  .glossary-modal,
  .menu-options-modal,
  .add-filter-modal,
  .add-register-modal,
  .edit-register-modal {
    --height: 100%;
    justify-content: end;
  }

  .register-modal {
    --height: 90%;
    --border-radius: 15px;
  }

  .custom-delete-modal {
    --min-height: 320px;
    --min-width: 30%;
    --border-radius: 20px;
    --height: 34%;
    --box-shadow: 0 0 30px rgba(0, 0, 0, 0.4) !important;

    ion-grid {
      margin-top: 2vh !important;
    }
  }

  .custom-modal {
    --min-height: 80%;
    --min-width: 40%;
    --border-radius: 20px;
  }
}

ion-icon.ion-accordion-toggle-icon.md.hydrated {
  color: var(--ion-color-light);
}

.accordeon-budget {
  ion-item {
    ion-icon.ion-accordion-toggle-icon.md.hydrated {
      color: var(--ion-color-primary) !important;
    }
  }
}

.glossary-icon {
  margin: auto 0;
  font-size: 20px;
  cursor: pointer;
}


ion-loading.custom-loader {
  --backdrop-opacity: 0.7;

  .loading-wrapper {
    --spinner-color: var(--ion-color-light);
    font-size: 18px;
    color: var(--ion-color-light);

    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  ion-spinner {
    transform: scale(2);
  }
}

.searchbar-input.sc-ion-searchbar-md {
  // background: transparent;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid var(--ion-color-grey-shade);
  font-size: 15px;
}

.searchbar-search-icon.sc-ion-searchbar-md,
.searchbar-cancel-button.sc-ion-searchbar-md {
  width: 28px;
  height: 28px;
  top: 9px;
  color: var(--ion-color-primary);
}

// cards de Clientes, Equipe, Fornecedores
.custom-register-card {
  padding: 0;

  ion-card {
    margin-bottom: 20px;

    ion-card-header {
      padding: 0;
      height: 110px;
      --background: url('/assets/images/bg/login-background.png') no-repeat center/110%;
    }

    ion-card-content {
      // margin: 0 45px;
      padding-right: 0;
      padding-left: 0;
      text-align: center;

      ion-avatar {
        margin: -60px auto 10px;
        width: 120px;
        height: 120px;
        border: 3px solid var(--ion-color-light);
      }

      .name {
        color: var(--ion-color-dark);
      }

      .work {
        color: var(--ion-color-dark);
        margin-bottom: 10px;
        font-weight: 500 !important;
      }

      ion-item {
        margin: 10px auto;
        display: table;
      }

      ion-label {
        overflow-wrap: anywhere;
        min-width: 45% !important;
        margin: 0;
      }

      .custom-label {
        display: contents;

        >h3 {
          font-size: 16px;
          color: var(--ion-color-border);
        }

        >ion-icon {
          font-size: 22px;
          margin-right: 5px;
          color: var(--ion-color-border);
        }
      }

      .fixed-data {
        color: var(--ion-color-secondary);
        font-size: 16px;
        text-align: left;
        margin: 0 0 0 8px;
        line-height: 1;
        white-space: normal;
      }
    }
  }
}

.desktop-card {
  width: 100%;

  ion-col {
    .favorite-icon {
      font-size: 20px;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }

    h3 {
      font-weight: 600 !important;
    }
  }

  .open-profile-column {
    cursor: pointer;
  }

  .supplier-column,
  .created-by-column,
  .employees-column {
    cursor: pointer;
    display: inline-flex;

    ion-avatar {
      width: 26px;
      height: 26px;
      min-height: 26px;
      min-width: 26px;
      margin: auto 0;
      margin-right: 6px;
    }
  }

  .supplier-column,
  .origin-column,
  .created-by-column {
    h3 {
      font-weight: 700 !important;
    }
  }

  .unity-column {
    h3 {
      text-transform: uppercase;
    }
  }
}

ion-segment {
  ion-segment-button {
    ion-label {
      font-family: 'Rajdhani';
      text-transform: initial;
      letter-spacing: 0;
      font-size: 15px;
    }
  }
}

.segment-desktop {
  width: 40%;
}

.segment-mobile {
  width: 100%;
}

ion-progress-bar::part(progress) {
  border-radius: 6px;
}

ion-progress-bar::part(track) {
  border-radius: 6px;
}

.icon-title {
  cursor: pointer;

  ion-icon {
    font-size: 24px;
  }
}

.choosed-row {
  --background: rgba(var(--ion-color-selected-rgba));
  background: rgba(var(--ion-color-selected-rgba));
}

.select-time-duration {
  display: flex;
  max-width: 200px;
}

.select-filter {
  margin: 0 !important;

  span {
    margin-right: 18px !important;
  }
}

.select-time-duration,
.select-filter {
  width: 100%;
  margin-top: 5px;

  span {
    color: var(--ion-color-border);
    font-size: 13px;
    font-weight: bold;
    margin-right: 7px;
    cursor: pointer;
    width: 25%;
    font-family: 'Rajdhani';

    &.active {
      color: var(--ion-color-primary);
    }
  }
}

.filter-mobile {
  margin: 15px 0 !important;

  span {
    font-size: 15px !important;
  }
}

.content-datetime {
  margin-left: 5px;
  border: 1px solid var(--ion-color-grey-shade);
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 33px;

  ion-input {
    width: 200px;
  }
}

ion-datetime-button::part(native) {
  background: transparent;
  font-family: 'Rajdhani';
  margin: 0;
}

.member-filter-chip {
  background: transparent;
  margin: 2px 10px;

  ion-label {
    color: var(--ion-color-dark);
  }
}

// WORK DETAILS, MODEL DETAILS
.content-details {
  background: var(--ion-color-medium);
  // width: 100%;
  min-height: 100vh;
}

.button-status {
  height: 30px !important;
  font-size: initial;
  margin-left: 10px;
}

.button-content-save {
  display: flex;
  align-items: center;
  margin-top: 10px;

  ion-button {
    margin-left: 10px;
    --border-radius: 8px;

    p {
      margin: 0;
      font-family: 'Rajdhani';
      font-size: 17px;
      font-weight: 700;
    }
  }

  ion-icon {
    font-size: 26px;
    margin-left: 5px;
  }
}

.content-section-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ion-icon {
    margin-right: 5px;
    font-size: 21px;
  }

  .title-single-work {
    margin: 0 12px;
    font-weight: bold;
    // font-size: 13px;
  }

  .text-name-team {
    margin: 0;
    margin-right: 10px;
  }

  .title-profile-work {
    font-weight: bold;
  }

  .title-color-primary {
    color: var(--ion-color-secondary);
    font-weight: bold;
    margin-left: 10px;
  }

  app-profile-view {
    margin: 0 12px;
  }
}

.section-actions {
  display: flex;
  height: 80px;
  align-items: flex-end;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  .link-page-single {
    cursor: pointer;
    position: relative;
  }

  .active-page {
    border: 3px solid var(--ion-color-primary);
    background: var(--ion-color-primary);
    border-radius: 8px 8px 0 0;
    position: absolute;
    right: 10px;
    left: 10px;
    bottom: 0;
  }

  h3 {
    margin: 0 16px;
    padding: 15px 0;
  }
}

.text-info-details {
  display: flex;
  align-items: center;
  margin-top: 15px;

  ion-icon {
    font-size: 22px;
    margin-left: 5px;
    cursor: pointer;
  }

  h2 {
    color: var(--ion-color-dark);
    margin: 0;
  }
}

.line-progress-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.team-container {
  margin-top: 10px;
}

.team-content {
  margin-right: 20px !important;
  display: flex;
  flex-direction: column;
}