/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/misc";

@import '~devextreme/dist/css/dx.material.lime.light.css';
@import '~devexpress-gantt/dist/dx-gantt.min.css';

/* Swiper imports */
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/pagination";
@import "swiper/scss/free-mode";

/* Quill imports */
@import '~quill/dist/quill.bubble.css'; 
@import '~quill/dist/quill.snow.css';

// Itens default
ion-item {
    --inner-padding-end: 0;
    --padding-start: 0;
    --padding-end: 0;
    --background-activated: none;
    --min-height: auto;
    margin-bottom: 8px;

    ion-label {
        color: var(--ion-color-font) !important;
    }

    .input-default {
        margin-top: 5px;
        font-size: 16px;
        border: 1px solid var(--ion-color-grey-shade);
        border-radius: 7px;
        font-weight: 600;
        --padding-start: 16px !important;
    }
}

.input-email-default {
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid var(--ion-color-grey-shade);
    border-radius: 7px;
    font-weight: 600;
    --padding-start: 16px !important;
}

ion-radio-group {
    ion-item {
        --padding-start: 10px;
    }
}

.logo-info-content {
    min-height: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

    img {
        height: 2rem;
    }

    h1 {
        font-size: 1.2rem;
        text-align: center;
        color: var(--ion-color-font);
        font-weight: 600 !important;
    }

}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    .logo-info-content h1 {
        font-size: 1.4rem;
    }
}

.form-login-content {
    padding: 0 3rem;

    ion-label {
        color: var(--ion-color-font) !important;
    }
}

// Buttons default

.button-default {
    height: 42px;
    font-size: 15px;
    line-height: 23px;
    font-weight: bold;
    text-transform: none;
    --border-width: 1px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgb(87 35 116 / 16%);
}

// Input error feedback

.text-error-input {
    color: var(--ion-color-danger);
    font-size: 12px;
}

.popover-wide {
    ion-label {
        font-weight: 500 !important;
        color: var(--ion-color-font) !important;
        padding: 0 5px;
        font-size: 12px !important;
        white-space: inherit !important;
    }
}

.modal-onboarding {
    --height: 100vh;
}

.fc {
  --fc-border-color: var(--ion-color-border);
  --fc-today-bg-color: var(--ion-color-secondary);
  height: 480px;

  .fc-button-primary {
    background-color: var(--ion-color-secondary) !important;
    border: none !important;
  }

  .fc-col-header-cell-cushion, .fc-toolbar-title {
    color: var(--ion-color-primary);
  }

  .fc-daygrid-day-number {
    color: var(--ion-color-font);
  }

  .fc-event-title-container {
    background-color: var(--ion-color-primary-tint);
  }

  .fc-scrollgrid {
    border-radius: 7px;
  }

  .fc-toolbar-title {
    text-transform: capitalize;
  }
}

ion-segment {
  justify-content: start;
  background: var(--ion-color-dark-contrast);
  overflow-x: auto;

  ion-segment-button {
    --color-checked: var(--ion-color-dark);
    --indicator-color: var(--ion-color-primary);
    --indicator-height: 8px;
    min-width: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &::part(indicator-background) {
      border-radius: 8px 8px 0 0;
    }

    &::part(indicator) {
      max-width: 50%;
      margin: auto;
    }
  }
}

.align-column-default {
  display: flex;
  align-items: center;

  ion-icon {
    margin-right: 8px;
    font-size: 10px;
    cursor: pointer;
  }
}

.item-content-date-search {
  margin-bottom: 0;

  .content-datetime {
      height: 44px;
  }
}

.header-modal-default {
  display: flex;
  gap: 2vw;
  align-items: center;
  border-bottom: solid var(--ion-color-medium) 1px;
  height: 8vh;
}

.info-material-single {
  display: flex;

  div {
      width: 50%;
  }
}

.result-material-select-text {
  h3 {
      font-weight: 500 !important;
  }
}

.search-materials {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;


  ion-searchbar {
      padding: 0;
  }
}

.div-input-option-material {
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;

  p {
      margin: 0 0 0 7px;
      color: var(--ion-color-border);
      font-weight: 500;
  }
}

.label-popover-budget {
  cursor: pointer;
}

.material-single-budget {
  .title-column-material {
      width: 30% !important;
  }

  .result-material-select-text {
      width: 70% !important;
  }
}

.div-loading-data {
  position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-status-popover {
  ion-item {
      cursor: pointer;
      height: 40px;

      ion-label {
          margin: 5px 12px;
      }

      ion-icon {
          margin: 0 10px;
      }
  }
}

.item-content-list-default {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--ion-color-medium);
  color: var(--ion-color-dark);
}

.hashtag {
  color: var(--ion-color-secondary);
  font-weight: bold;
}