// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

/** Rajdhani Font **/
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Rajdhani-Light.ttf');
}

@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Rajdhani-Regular.ttf');
}

@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Rajdhani-Medium.ttf');
}

@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Rajdhani-SemiBold.ttf');
}

@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Rajdhani-Bold.ttf');
}

/** OpenSans Font **/
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/OpenSans/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/OpenSans/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/OpenSans/OpenSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/OpenSans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf');
}

:root {
  --ion-font-family: 'OpenSans';

  /** primary **/
  --ion-color-primary: #99BB14;
  --ion-color-primary-rgb: 153, 187, 20;
  --ion-color-primary-rgba: 153, 187, 20, 0.5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #87a512;
  --ion-color-primary-tint: #a3c22c;

  --ion-color-secondary: #0081C7;
  --ion-color-secondary-rgb: 0, 129, 199;
  --ion-color-secondary-rgba: 0, 129, 199, 0.4;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0072af;
  --ion-color-secondary-tint: #1a8ecd;

  --ion-color-tertiary: #002032;
  --ion-color-tertiary-rgb: 0, 32, 50;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #001c2c;
  --ion-color-tertiary-tint: #1a3647;

  --ion-color-quaternary: #004164;
  --ion-color-quaternary-rgb: 0, 65, 100;
  --ion-color-quaternary-contrast: #ffffff;
  --ion-color-quaternary-contrast-rgb: 255, 255, 255;
  --ion-color-quaternary-shade: #003958;
  --ion-color-quaternary-tint: #1a5474;

  --ion-color-success: #14BB62;
  --ion-color-success-rgb: 20, 187, 98;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #12a556;
  --ion-color-success-tint: #2cc272;

  --ion-color-warning: #FFA630;
  --ion-color-warning-rgb: 255, 166, 48;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0922a;
  --ion-color-warning-tint: #ffaf45;

  --ion-color-danger: #D02541;
  --ion-color-danger-rgb: 208, 37, 65;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b72139;
  --ion-color-danger-tint: #d53b54;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #e4e6ed;
  --ion-color-medium-rgb: 228, 230, 237;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #d6d7d9;
  --ion-color-medium-tint: #f4f5f8;

  /** grey **/
  --ion-color-grey: #E6E9EF;
  --ion-color-grey-rgb: 230, 233, 239;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #cacdd2;
  --ion-color-grey-tint: #e9ebf1;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-rgba: 244, 245, 248, 0.5;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** border **/
  --ion-color-border: #707070;
  --ion-color-border-rgb: 112, 112, 112;
  --ion-color-border-contrast: #ffffff;
  --ion-color-border-contrast-rgb: 255, 255, 255;
  --ion-color-border-shade: #636363;
  --ion-color-border-tint: #7e7e7e;

  /** font **/
  --ion-color-font: #333333;
  --ion-color-font-rgb: 51, 51, 51;
  --ion-color-font-contrast: #ffffff;
  --ion-color-font-contrast-rgb: 255, 255, 255;
  --ion-color-font-shade: #2d2d2d;
  --ion-color-font-tint: #474747;

  /** Button Google **/
  --ion-color-google: #DC4234;
  --ion-color-google-rgb: 220, 66, 52;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #c23a2e;
  --ion-color-google-tint: #e05548;

  /** selected **/
  --ion-color-selected: #7FC0E3;
  --ion-color-selected-rgb: 127, 192, 227;
  --ion-color-selected-rgba: 127, 192, 227, 0.3;
  --ion-color-selected-contrast: #000000;
  --ion-color-selected-contrast-rgb: 0, 0, 0;
  --ion-color-selected-shade: #70a9c8;
  --ion-color-selected-tint: #8cc6e6;
}
